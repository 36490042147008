<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('mtrlForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-row>
      <el-col :md="8" :offset="7" v-if="warningTitle">
        <div class="vd_color_red">！{{ warningTitle }}</div>
      </el-col>
    </el-row>
    <el-form ref="mtrlForm" :model="mtrlForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="6">
          <el-form-item label="物料图片">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mtrlForm.imge_id" :imgeUrl="mtrlForm.imge_url" :imgeUrlO="mtrlForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer"></i>
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料编号" prop="mtrl_no">
            <!-- <el-input v-model="mtrlForm.mtrl_no" @input ="mtrlForm.mtrl_no = helper.keepEngNum1(mtrlForm.mtrl_no)" placeholder="请填写账套编号（仅支持数字和英文和-）" minlength="30"/> -->
            <el-tooltip class="item" effect="dark" content="仅限两位大写英文字母" placement="top-start">
              <el-input class="mtrlNo" v-model="mtrlForm.mtrl_no" @input="mtrlNoInput" placeholder="例:CR" show-word-limit>
                <template slot="prepend">{{ mtrlForm.acct_no }}</template>
                <template slot="append"> {{ mtrlForm.mtrl_color }}<span v-if="mtrlForm.mtrl_color">C</span></template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="物料名称" prop="mtrl_name">
            <el-input v-model="mtrlForm.mtrl_name" maxlength="30" show-word-limit placeholder="请填写物料名称"></el-input>
          </el-form-item>
          <el-form-item label="物料类型" prop="mtrl_type">
            <el-select v-model="mtrlForm.mtrl_type" placeholder="请选择物料类型" clearable>
              <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="安全库存" prop="mtrl_stock_limit">
            <el-input
              v-model="mtrlForm.mtrl_stock_limit"
              @input="val => (mtrlForm.mtrl_stock_limit = keep4Decimal(val))"
              maxlength="7"
              show-word-limit
              placeholder="请填写库存警告数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="物料克重" prop="mtrl_weight">
            <el-input
              v-model="mtrlForm.mtrl_weight"
              @input="mtrlForm.mtrl_weight = helper.keepTNum1(mtrlForm.mtrl_weight)"
              @blur="mtrlForm.mtrl_weight = helper.retain1(mtrlForm.mtrl_weight, 2, 10000)"
              maxlength="7"
              show-word-limit
              placeholder="请填写物料克重"
            >
              <template slot="append">克</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料单价" prop="mtrl_price">
            <el-input
              v-model="mtrlForm.mtrl_price"
              @input="mtrlForm.mtrl_price = helper.keepTNum1(mtrlForm.mtrl_price)"
              @blur="mtrlForm.mtrl_price = helper.calcPrice(mtrlForm.mtrl_price, 7, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写物料生产单价"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料规格" prop="mtrl_spec">
            <el-input
              type="textarea"
              rows="1"
              v-model="mtrlForm.mtrl_spec"
              maxlength="50"
              show-word-limit
              placeholder="请填写物料规格"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="潘通色号" v-if="mtrlForm.mtrl_type === 0" prop="mtrl_color">
            <el-tooltip class="item" effect="dark" content="仅限四位数字" placement="top-start">
              <el-input class="mtrlcolor" v-model="mtrlForm.mtrl_color" @input="mtrlColorInput" show-word-limit placeholder="仅限四位数字">
                <template slot="append"> <span v-if="mtrlForm.mtrl_color != ''">C</span></template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="物料单位" prop="mtrl_unit">
            <el-select v-model="mtrlForm.mtrl_unit" placeholder="请选择物料类型" clearable>
              <el-option v-for="item in mtrlTypeGroupOpt" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物料毛高" prop="mtrl_thick">
            <el-input
              v-model="mtrlForm.mtrl_thick"
              @input="mtrlForm.mtrl_thick = helper.keepTNum1(mtrlForm.mtrl_thick)"
              @blur="mtrlForm.mtrl_thick = helper.retain1(mtrlForm.mtrl_thick, 2, 1000)"
              maxlength="6"
              show-word-limit
              placeholder="请填写物料毛高"
            >
              <template slot="append">毫米</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料门幅" prop="mtrl_width">
            <el-input
              v-model="mtrlForm.mtrl_width"
              maxlength="9"
              show-word-limit
              @blur="mtrlForm.mtrl_width = helper.calcPrice(mtrlForm.mtrl_width, 4, 10000)"
              @input="mtrlForm.mtrl_width = helper.keepTNum1(mtrlForm.mtrl_width)"
              placeholder="请填写物料门幅"
            >
              <template slot="append">米</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料成分" prop="mtrl_comp">
            <!-- <el-input v-model="mtrlForm.mtrl_comp" maxlength="30" show-word-limit placeholder="请填写物料成分"></el-input> -->
            <el-select v-model="mtrlForm.mtrl_comp" placeholder="请选择物料成分" clearable>
              <el-option v-for="item in compType" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专属客户" prop="cust_name">
            <el-select filterable v-model="mtrlForm.cust_name" placeholder="请选择" clearable>
              <el-option v-for="item in custList" :key="item.value" :label="item.label" :value="item.label"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="其他备注" prop="mtrl_remark">
            <el-input
              type="textarea"
              rows="1"
              v-model="mtrlForm.mtrl_remark"
              maxlength="150"
              show-word-limit
              placeholder="请填写物料备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, getNoCatch, postNoCatch } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import { optnAPI } from '@api/modules/optn';
import addHeader from '@/views/component/addHeader';
import { imgeAPI } from '@api/modules/imge';
import helper from '@assets/js/helper';
import inputUser from '@/views/component/inputUser';
import imgPopover from '@/views/component/imgPopover';
import { keep4Decimal } from '@assets/js/regExUtil';
import { custAPI } from '@api/modules/cust';
import { cloneDeep } from 'lodash';

export default {
  name: 'mtrlAddMain',
  components: {
    addHeader,
    inputUser,
    imgPopover
  },
  data() {
    return {
      rules: {
        mtrl_no: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_name: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_type: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_unit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_thick: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_stock_limit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_color: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        supp_id: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_weight: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_comp: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_price: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_width: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_spec: [{ required: false, trigger: 'blur', message: ' ' }]
      },
      rules2: {
        mtrl_no: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_name: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_type: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_unit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_thick: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_stock_limit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_color: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        supp_id: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_weight: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_comp: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_price: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_width: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_spec: [{ required: false, trigger: 'blur', message: ' ' }]
      },
      mtrlForm: {
        mtrl_no: '',
        mtrl_name: '',
        cust_name: '',
        mtrl_type: null,
        mtrl_spec: '',
        mtrl_color: '',
        mtrl_weight: '',
        mtrl_width: null,
        mtrl_unit: '',
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        mtrl_thick: '',
        mtrl_comp: '',
        mtrl_stock_limit: 0,
        mtrl_price: '',
        mtrl_remark: '',
        supp_id: '',
        user_id: null,
        stff_id: null,
        dept_id: null,
        status: 0,
        key: 0
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      mtrlTypeGroupOpt: [],
      suppType: [],
      compType: [],
      warningTitle: '',
      custList: []
    };
  },
  watch: {
    'mtrlForm.mtrl_type'(newVal) {
      switch (newVal) {
        case 0:
          this.rules.mtrl_comp[0].required = true;
          this.rules.mtrl_price[0].required = true;
          this.rules.mtrl_width[0].required = true;
          this.rules.mtrl_thick[0].required = true;
          this.rules.mtrl_weight[0].required = true;
          this.rules.mtrl_color[0].required = true;
          break;
        case 1:
          this.rules.mtrl_comp[0].required = true;
          this.rules.mtrl_price[0].required = true;
          this.rules.mtrl_spec[0].required = true;
          this.rules.mtrl_width[0].required = false;
          this.rules.mtrl_thick[0].required = false;
          this.rules.mtrl_weight[0].required = false;
          this.rules.mtrl_color[0].required = false;
          break;
        case 3:
          this.rules = cloneDeep(this.rules2);
      }
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    let syncData = this.$route.params.syncData;
    if (syncData) {
      this.mtrlForm = Object.assign({}, this.mtrlForm, syncData);
    }
  },
  methods: {
    keep4Decimal,
    initData() {
      this.getMtrlType();
      this.getCompType();
      this.getCustList();
      // this.getSuppsList()
    },
    getImgId() {},
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let requestBody = cloneDeep(this.mtrlForm);
      requestBody.mtrl_type === 0 ? (requestBody.mtrl_color += 'C') : '';
      requestBody.mtrl_type === 0 ? (requestBody.mtrl_no += requestBody.mtrl_color) : '';
      const staffForm = this.$refs.userData.getData();
      requestBody = Object.assign(requestBody, staffForm);
      postNoCatch(mtrlAPI.addMtrl, requestBody)
        .then(({ data }) => {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          const permId = this.$route.query.perm_id;
          this.jump(`/mtrl_edit?perm_id=${permId}&form_id=${data.form_id}`);
          this.resetForm('mtrlForm');
        })
        .catch(res => console.log(res));
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('mtrlForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 商户下拉框下拉
    suppChange() {
      // this.getSuppsList()
    },
    getCustList() {
      getNoCatch(custAPI.getAllCustsV1).then(({ data }) => {
        this.custList = Array.from(data, item => {
          return { value: item.cust_id, label: item.cust_name };
        });
      });
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getAllContent, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.compType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mtrlForm.imge_id = res.data.imge_id;
        this.mtrlForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime, 0);
        this.mtrlForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime, 0);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload() {},
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mtrlForm.imge_url = null;
      this.mtrlForm.imge_urlO = null;
      this.mtrlForm.imge_id = null;
    },
    mtrlNoInput() {
      this.mtrlForm.mtrl_no = this.helper.keepEnglish(this.mtrlForm.mtrl_no).toUpperCase();
      this.mtrlForm.mtrl_no = this.mtrlForm.mtrl_no.substr(0, 2);
    },
    mtrlColorInput() {
      this.mtrlForm.mtrl_color = this.helper.keepTNum(this.mtrlForm.mtrl_color);
      this.mtrlForm.mtrl_color = this.mtrlForm.mtrl_color.substr(0, 4);
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_cussor {
  cursor: default;
}
::v-deep .mtrlNo .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .mtrlcolor .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .el-input-group__append {
  color: #000000;
}
.vd_color_red {
  color: #ff0000;
}
</style>
