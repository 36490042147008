/**
 * 正则工具类
 * @author HuangJun
 */
const onlyNumberAndPoint = val =>
  val
    .replace(/[^\d.]/g, '')
    .replace(/\.{2,}/g, '.')
    .replace(/^\./g, '')
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.');
export const keepLetter2AndNumber5 = val => {
  let prefixStr = val
    .substring(0, 2)
    ?.replace(/[^A-Za-z]/g, '')
    .toUpperCase();
  let subStr = val.substring(2, val.length)?.replace(/[^0-9]/g, '');
  return prefixStr + subStr;
};
export const keep6Decimal = val => onlyNumberAndPoint(val).replace(/^(\d+)\.(\d\d\d\d\d\d).*$/, '$1.$2');
export const keep5Decimal = val => onlyNumberAndPoint(val).replace(/^(\d+)\.(\d\d\d\d\d).*$/, '$1.$2');
export const keep4Decimal = val => onlyNumberAndPoint(val).replace(/^(\d+)\.(\d\d\d\d).*$/, '$1.$2');
export const keep3Decimal = val => onlyNumberAndPoint(val).replace(/^(\d+)\.(\d\d\d).*$/, '$1.$2');
export const keep2Decimal = val => onlyNumberAndPoint(val).replace(/^(\d+)\.(\d\d).*$/, '$1.$2');
export const keep1Decimal = val => onlyNumberAndPoint(val).replace(/^(\d+)\.(\d).*$/, '$1.$2');
export const keepNumber = val => val.replace(/\D/g, '');
export const keepNumberAndSpecialCharactersAndSpaces = val => val.replace(/[^0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/g, '');
export const removeAroundSpaces = val => val.replace(/^\s+|\s+$/g, '');
export const keepEngAndNumUp = val => val.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
export const keepEngAndNumLow = val => val.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
export const keepEngAndSpace = val => val.replace(/[^a-zA-Z0-9\s.,\-]/g, '');
